import React from "react"
import { Link } from "gatsby"
import { StyledSideMenu } from "./Sidemenu"
import { useHubPages } from "../../hooks/useHubPages"

export const SideMenuNews = () => {
  const { areas, sectors } = useHubPages()
  return (
    <StyledSideMenu className="position-static w-100">
      <h2>
        <Link to="/property-news-uk">Categories</Link>
      </h2>

      {/* Static Category Pages */}
      <Link to="/news/uk-property-news" activeClassName="link-active">
        News
      </Link>
      <Link to="/news/guidance" activeClassName="link-active">
        Guidance
      </Link>
      <Link to="/news/lifestyle" activeClassName="link-active">
        Lifestyle
      </Link>
      <Link to="/news/south-africa" activeClassName="link-active">
        South African Perspective
      </Link>

      <div className="group-heading">Articles By Location:</div>
      {areas.map(page => {
        return (
          <Link
            key={page.id}
            to={`/news/${page.key}`}
            activeClassName="link-active"
          >
            {page.subtitle}
          </Link>
        )
      })}
      <div className="group-heading">Articles By Sector:</div>
      {sectors.map(page => {
        return (
          <Link
            key={page.id}
            to={`/news/${page.key}`}
            // className={`bg-${page.key}`}
            activeClassName={`bg-${page.key}`}
          >
            {page.subtitle}
          </Link>
        )
      })}
    </StyledSideMenu>
  )
}
