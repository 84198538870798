import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useHubPages } from "../../hooks/useHubPages"
import { mediaBreakpoint } from "../../utils/breakpoints"

export const StyledSideMenu = styled.nav`
  /* Positioning Logic */
  position: absolute;
  z-index: 1;
  width: calc(100% - 30px);
  margin-bottom: 3rem;
  /* End Positioning Logic */

  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  > a {
    display: block;
    color: #fff;
    padding: 10px 15px 10px 30px;
    transition: 0.3s all ease-in-out;

    &:hover,
    &.link-active {
      padding: 10px 15px 10px 15px;
      text-decoration: none;
      background-color: ${({ theme }) => theme.secondary};
    }
  }
  h2 {
    padding: 15px 0;
    margin: 0;
    text-transform: uppercase;

    a {
      padding: 10px 15px;
      display: block;
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .group-heading {
    font-family: "Radikal-Bold", sans-serif;
    color: #b9c3c7;
    padding: 15px 15px;
    margin: 0;
    text-transform: uppercase;
  }

  @media ${mediaBreakpoint.down.lg} {
    position: static;
  }
`

export const Sidemenu = ({
  title = "Quick Links",
  titleLink = "/",
  pages = [],
}) => {
  const { areas, sectors } = useHubPages()

  if (!pages || !pages.length) return null

  return (
    <StyledSideMenu>
      <h2>
        <Link to={titleLink}>{title}</Link>
      </h2>

      {pages.map(page => {
        return (
          <Link key={page.id} to={page.permalink} activeClassName="link-active">
            {page.title}
          </Link>
        )
      })}

      {/* Static Pages - Case Studies & Testimonials */}
      <Link
        to="/property-investment-company/case-studies"
        activeClassName="link-active"
      >
        Case Studies
      </Link>
      <Link to="/testimonials" activeClassName="link-active">
        Testimonials
      </Link>

      <div className="group-heading">Investments By Location:</div>
      {areas.map(page => {
        return (
          <Link key={page.id} to={page.permalink} className={`bg-${page.key}`}>
            {page.subtitle}
          </Link>
        )
      })}
      <div className="group-heading">Investments By Sector:</div>
      {sectors.map(page => {
        return (
          <Link key={page.id} to={page.permalink} className={`bg-${page.key}`}>
            {page.subtitle}
          </Link>
        )
      })}
    </StyledSideMenu>
  )
}
